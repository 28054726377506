import React, { useEffect, useState } from "react";
import SearchMentor from "../component/SearchMentor";
import * as mentorshipAction from "../../../redux/actions/mentorshipAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../library/common/Loader";
import JitsiMeet from "./MeetingContainer";

const SearchMentorContainer = () => {
  const dispatch = useDispatch();

  const [joinMeeting, setjoinMeeting] = useState(false);

  const userName = useSelector(
    (state) => state?.userDetails?.data?.response?.basicDetails?.first_name
  );
  const mentorshipSubjects = useSelector(
    (state) => state?.getMentorshipSubjects?.data?.response
  );
  const upcomingSessionsData = useSelector(
    (state) => state?.getMentorshipUpcomingSessions
  );
  const mentorsListData = useSelector((state) => state?.getMentorsList);
  const reqMentorshipData = useSelector(
    (state) => state?.postMentorshipRequest
  );
  const getMyMentorsData = useSelector((state) => state?.getMyMentors);
  const cancelReqData = useSelector(
    (state) => state?.postMentorshipRequestCancel
  );

  const cancelSessionData = useSelector(
    (state) => state?.postMentorshipSessionCancel
  );
  function getUpcomgSessions(params) {
    dispatch(mentorshipAction?.getMentorshipUpcomingSessions());
  }
  useEffect(() => {
    dispatch(mentorshipAction?.getMentorshipSubjects());
    dispatch(mentorshipAction?.getMyMentors());
    getUpcomgSessions();
  }, []);
  useEffect(() => {
    if (!cancelReqData.isLoading && cancelReqData.status === "SUCCESS") {
      dispatch(mentorshipAction?.getMyMentors());
    }
  }, [cancelReqData]);
  useEffect(() => {
    if (
      !cancelSessionData.isLoading &&
      cancelSessionData.status === "SUCCESS"
    ) {
      getUpcomgSessions();
    }
  }, [cancelSessionData]);
  function getMentorsList(params) {
    if (params)
      dispatch(
        mentorshipAction.getMentorsList({
          searchText: params,
        })
      );
    else dispatch(mentorshipAction.clearMentorsList());
  }
  function submitRequest(params) {
    dispatch(mentorshipAction.postRequestMentorship(params));
  }

  function onCancel(params) {
    dispatch(mentorshipAction?.postRequestMentorshipCancel({ uuid: params }));
  }

  function onCancelSession(params) {
    dispatch(mentorshipAction?.postRequestMentorshipCancel({ uuid: params }));
  }
  function handleEndCall(params) {
    setjoinMeeting(false);
    window.location.reload();
  }

  const [profileClick, setProfileClick] = useState(false);
  const [selectedProfileID, setselectedPRofileID] = useState("");
  function handleSearchProfileClick(id, userType) {
    setselectedPRofileID(id);
    setProfileClick(true);
  }
  function handleProfileBackClick() {
    setProfileClick(false);
  }
  return (
    <div>
      {mentorsListData?.isLoading && <Loader />}
      {reqMentorshipData?.isLoading && <Loader />}
      {getMyMentorsData?.isLoading && <Loader />}
      {joinMeeting ? (
        <JitsiMeet
          joinMeeting={joinMeeting}
          handleEndCall={() => handleEndCall()}
        />
      ) : (
        <SearchMentor
          mentorshipSubjects={mentorshipSubjects ?? []}
          getMentorsList={getMentorsList}
          mentorsList={mentorsListData?.data?.response?.userList ?? []}
          submitRequest={submitRequest}
          reqMentorshipData={reqMentorshipData}
          getMyMentorsData={getMyMentorsData}
          onCancel={onCancel}
          upcomingSessionsData={upcomingSessionsData?.data?.response ?? []}
          userName={userName}
          setjoinMeeting={setjoinMeeting}
          onCancelSession={onCancelSession}
          handleSearchProfileClick={handleSearchProfileClick}
          profileClick={profileClick}
          selectedProfileID={selectedProfileID}
          handleProfileBackClick={handleProfileBackClick}
        />
      )}
    </div>
  );
};

export default SearchMentorContainer;
