import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Search.scss";
import SearchBar from "../../../library/custom/searchBar/searchBar";
import RadioButton from "../../../library/custom/radioButton/RadioButton";
import MentorUserCard from "../../../library/custom/mentorUserCard/MentorUserCard";
import { MentorShipRequestCard } from "../../../library/custom/mentorshipRequestCard/MentorShipRequestCard";
import RequestMentorshipForm from "./RequestMentorshipForm";
import SearchAutoComplete from "../../../library/custom/searchAutoComplete/SearchAutoComplete";
import { useNavigate } from "react-router-dom";
import { MENTORSHIP_BOOK_SESSION } from "../../../constant/routeContant";
import SessionUserCard from "./SessionUserCard";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import NoDataDisplayCard from "../../../library/common/NoDataDisplayCard";
import { useSelector } from "react-redux";
import ViewProfileContainer from "../../search/container/ViewProfileContainer";
const SearchMentor = ({
  mentorshipSubjects,
  getMentorsList,
  mentorsList,
  submitRequest,
  reqMentorshipData,
  getMyMentorsData,
  onCancel,
  upcomingSessionsData,
  userName,
  setjoinMeeting,
  onCancelSession,
  handleSearchProfileClick,
  profileClick,
  selectedProfileID,
  handleProfileBackClick,
}) => {
  console.log(userName);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [searchText, setSearchText] = useState(undefined);
  const [selectedUser, setselectedUser] = useState();
  const [byNameOrSkill, setbyNameOrSkill] = useState("skill");
  const [notAcceptedReuests, setnotAcceptedReuests] = useState([]);
  const [acceptedRequests, setAcceptedRequests] = useState([]);
  useEffect(() => {
    if (!reqMentorshipData.isLoading && reqMentorshipData.status === "SUCCESS")
      setOpenModal(false);
  }, [reqMentorshipData]);

  const [debouncedValue, setDebouncedValue] = useState("");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValue(searchText);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [searchText]);
  useEffect(() => {
    getMentorsList(debouncedValue);
  }, [debouncedValue]);
  useEffect(() => {
    if (!getMyMentorsData.isLoading && getMyMentorsData.status === "SUCCESS") {
      let notAcceptedRequests = [];
      let acceptedRequests = [];
      notAcceptedRequests = getMyMentorsData?.data?.response?.rows?.filter(
        (i) => i.action === "Pending" || i.action === "Session Request Pending"
      );
      acceptedRequests = getMyMentorsData?.data?.response?.rows?.filter(
        (i) =>
          i.action === "Accepted" ||
          i.action === "Cancelled" ||
          i.action === "Session Request Accepted" ||
          i.action === "Session Request Pending"
      );
      setnotAcceptedReuests(notAcceptedRequests);
      setAcceptedRequests(acceptedRequests);
    }
  }, [getMyMentorsData]);
  function isCurrentTimeNear(timeRange) {
    const currentTime = new Date();

    // Parse the input string
    const [datePart, timePart] = timeRange.split(" ");
    const [year, month, day] = datePart.split("-").map(Number);
    const [startTime, endTime] = timePart.split("-");
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);

    // Create Date objects for the start and end times
    const startDateTime = new Date(
      year,
      month - 1,
      day,
      startHour,
      startMinute
    );
    const endDateTime = new Date(year, month - 1, day, endHour, endMinute);

    // Calculate the "near" range
    const oneHour = 60 * 60 * 1000; // One hour in milliseconds
    const nearStartTime = new Date(startDateTime.getTime() - oneHour);
    const nearEndTime = new Date(endDateTime.getTime() + oneHour);

    // Check if the current time is within the "near" range
    return currentTime >= nearStartTime && currentTime <= nearEndTime;
  }

  const timeRange = "2024-06-10 14:00-15:00";
  useEffect(() => {
    console.log(isCurrentTimeNear(timeRange));
  }, []);
  const timeZone = useSelector(
    (state) =>
      state?.userDetails?.data?.response?.profile?.personal_details?.timezone ??
      ""
  );

  const timeZoneLoading = useSelector((state) => state?.userDetails?.isLoading);
  return (
    <>
      {profileClick ? (
        <ViewProfileContainer
          user={selectedProfileID}
          onClick={handleProfileBackClick}
          fromSerach={true}
        />
      ) : (
        <div>
          {openModal && (
            <RequestMentorshipForm
              open={openModal}
              mentorshipSubjects={mentorshipSubjects}
              mentorData={selectedUser}
              handleCancel={() => {
                setOpenModal(false);
              }}
              submitRequest={submitRequest}
            />
          )}
          <Grid mt={2} container className="overrideSearch">
            <Grid container item lg={8} xs={12}>
              <p className="pageHeading">Search Mentors</p>
            </Grid>
            <Grid conainer item lg={12}>
              <Grid container item lg={12}>
                <h1 className="filterLabel">Search By</h1>
              </Grid>
              <Grid
                textAlign={"left"}
                container
                gap={"10px"}
                item
                lg={4}
                xs={12}
              >
                <Grid container item lg={5.5} xs={5}>
                  <RadioButton
                    value="skill"
                    onChange={(e) => {
                      setbyNameOrSkill(e.target.value);
                    }}
                    label="Skill"
                    checked={byNameOrSkill === "skill"}
                  />
                </Grid>
                <Grid container item lg={5.5} xs={5}>
                  <RadioButton
                    value="name"
                    onChange={(e) => {
                      setbyNameOrSkill(e.target.value);
                    }}
                    label="Name"
                    checked={byNameOrSkill === "name"}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container mt={1} item lg={8} xs={12}>
              {byNameOrSkill === "skill" ? (
                <SearchAutoComplete
                  optionsData={mentorshipSubjects ?? []}
                  value={searchText}
                  onChange={(e, value) => {
                    setSearchText(value);
                  }}
                  typeOnchange={() => {}}
                />
              ) : (
                <SearchBar
                  placeholder={"Search"}
                  searchbarInput={searchText}
                  handleSearch={(e) => {
                    setSearchText(e);
                  }}
                />
              )}
            </Grid>
            <Grid gap={1} container mt={2} item lg={12} xs={12}>
              {mentorsList.map((i) => (
                <MentorUserCard
                  key={i.id}
                  name={`${i.first_name} ${i.last_name}`}
                  profilePic={i.profile_pic}
                  designation={i.designation}
                  companyName={i.company}
                  state={i.state}
                  city={i.city}
                  country={i.country_name}
                  skills={i?.mentorship?.subjects?.join(", ") ?? ""}
                  onClick={() => {
                    setselectedUser(i);
                    setOpenModal(true);
                  }}
                  total_experience={i?.total_experience}
                  id={i.id}
                  onProfileClick={() =>
                    handleSearchProfileClick({
                      id: i.id,
                      // userType: account_type,
                    })
                  }
                />
              ))}
            </Grid>

            <Grid mt={4} container item lg={8} xs={12}>
              <p className="pageHeading">Mentorship Requests</p>
            </Grid>
            <Grid mt={4} container item lg={12} md={12} sm={12} xs={12}>
              <Grid container gap={2}>
                {notAcceptedReuests?.length > 0 ? (
                  notAcceptedReuests.map((i) => (
                    <MentorShipRequestCard
                      name={`${i.mentorFirstName} ${i.mentorlastName}`}
                      skills={i.skills}
                      onReject={() => onCancel(i.uuid)}
                      profilePic={i.mentorProfilePic}
                    />
                  ))
                ) : (
                  <NoDataDisplayCard />
                )}
              </Grid>
            </Grid>
            <Grid mt={4} container item lg={8} xs={12}>
              <p className="pageHeading">My Mentors</p>
            </Grid>
            <Grid mt={2} container item lg={12} md={12} sm={12} xs={12}>
              <Grid container gap={2}>
                {acceptedRequests?.length > 0 ? (
                  acceptedRequests.map((i) => (
                    <MentorShipRequestCard
                      name={`${i.mentorFirstName} ${i.mentorlastName}`}
                      profilePic={i.mentorProfilePic}
                      skills={i.skills}
                      isAccept={false}
                      onBook={() =>
                        navigate(MENTORSHIP_BOOK_SESSION, {
                          state: { mentorId: i.mentorId, uuid: i.uuid },
                        })
                      }
                      isCancel={false}
                      isBook={true}
                      bookLabel={"Book your Session"}
                    />
                  ))
                ) : (
                  <NoDataDisplayCard />
                )}
              </Grid>
            </Grid>

            <Grid container mt={4}>
              <FormLabels label={"Upcoming Mentorship sessions"} />
            </Grid>
            {timeZoneLoading ? (
              ""
            ) : (
              <Grid container item mt={2}>
                {upcomingSessionsData?.length > 0 ? (
                  upcomingSessionsData.map((i) =>
                    i.mentorFirstName?.toUpperCase() ==
                    userName?.toUpperCase() ? (
                      ""
                    ) : (
                      <SessionUserCard
                        name={`${i.mentorFirstName} ${i.mentorlastName}`}
                        profilePic={i.mentorProfilePic}
                        designation={i.menteeDesignation}
                        date={i.session_date}
                        time={i.session_start_time}
                        onCancel={() => {
                          onCancelSession(i.uuid);
                        }}
                        joinMeeting={() => {
                          // isCurrentTimeNear(`${i?.session_date} ${i?.session_start_time}-${i?.session_end_time}`) ?
                          setjoinMeeting(i.uuid);
                          // :  alert("can't join now ")
                        }}
                        timeZone={timeZone}
                      />
                    )
                  )
                ) : (
                  <NoDataDisplayCard />
                )}
              </Grid>
            )}
          </Grid>
        </div>
      )}
    </>
  );
};

export default SearchMentor;
